import OrderBookComponent from '../../components/trading/OrderBookComponent'
import DepthChartComponent from '../../components/trading/charts/DepthChartComponent'
import Grid from '@mui/material/Grid2'
import { OrderBookStateProvider } from '../../services/useOrderBookState'
import SettingsComponent from '../../components/trading/SettingsComponent'
import NewOrderComponent from '../../components/trading/NewOrderComponent'
import MarketComponent from '../../components/trading/MarketComponent'
import TradesComponent from '../../components/trading/TradesComponent'

export default function TerminalPage() {
  return (
    // @ts-ignore
    <OrderBookStateProvider>
      <Grid container maxWidth={'xxl'} spacing={1}>
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <OrderBookComponent />
        </Grid>
        <Grid size={{ md: 12, lg: 6 }} order={{ md: -1, lg: 0 }}>
          <Grid container direction="column" spacing={1}>
            <Grid>
              <DepthChartComponent />
            </Grid>
            <Grid>
              <TradesComponent />
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <Grid container direction="column" spacing={1}>
            <Grid>
              <NewOrderComponent />
            </Grid>
            <Grid>
              <MarketComponent />
            </Grid>
            <Grid>
              <SettingsComponent />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </OrderBookStateProvider>
  )
}
