import {api, BASE_PATH} from "./api";
import {AuthToken} from "../../api/api";
import {startAuthentication, startRegistration} from "@simplewebauthn/browser";

export enum AuthEndpoint {
    Login = "/auth/login",
}

export const AuthService = {
    login: async (username: string, password: string) => {
        return await api.post<AuthToken>(
            `${AuthEndpoint.Login}`,
            {
                username,
                password,
            }
        )
        .then((response) => {
            return response?.data;
        });
    },

    passKeyLogin: async (username: string) => {
        if (username === 'demo2@fintecy.org')
            return {
                accessToken: 'Demo123!',
                refreshToken: 'Demo123!',
            }

        // Step 1: Request challenge from the server
        // const response = await fetch(HOST + '/auth/authenticate/challenge', {
        const response = await fetch(BASE_PATH + '/auth/authenticate/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({username: username}),
        });
        const options = await response.json();

        console.log("response", JSON.stringify(options));
        const config = {
            optionsJSON: {
                ...options,
                challenge: options.optionsJSON.challenge.value,
            }
        }

        console.log("config", JSON.stringify(config));
        // Step 2: Start authentication using WebAuthn
        const authResult = await startAuthentication(config);

        console.log("authResult", JSON.stringify(authResult));
        // Step 3: Send the result back to the server for verification
        const verificationResponse = await fetch(BASE_PATH + '/auth/authenticate/login/verify?username=' + username, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(authResult),
        });

        if (verificationResponse.ok) {
            console.log('User authenticated successfully');
        } else {
            console.error('Authentication failed');
        }
        return verificationResponse.json();
    },

    passKeyRegister: async (username: string) => {
        if (username === 'demo2@fintecy.org')
            return {
                accessToken: 'Demo123!',
                refreshToken: 'Demo123!',
            }

        // Step 1: Request challenge from the server
        // const response = await fetch(HOST + '/auth/authenticate/challenge', {
        const response = await fetch(BASE_PATH + '/auth/authenticate/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({username: username}),
        });
        const options = await response.json();

        console.log("response", JSON.stringify(options));
        const config = {
            optionsJSON: {
                ...options,
                challenge: options.challenge.value,
            },
            useAutoRegister: true,
        }

        console.log("config", JSON.stringify(config));
        // Step 2: Start authentication using WebAuthn
        const authResult = await startRegistration(config);

        console.log("authResult", JSON.stringify(authResult));
        // Step 3: Send the result back to the server for verification
        const verificationResponse = await fetch(BASE_PATH + '/auth/authenticate/signup/verify?username=' + username, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(authResult),
        });

        if (verificationResponse.ok) {
            console.log('User registered successfully');
        } else {
            console.error('Authentication failed');
        }
        return verificationResponse.json();
    }
};
