import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import ButtonGroup from '@mui/material/ButtonGroup'
import { Link } from 'react-router-dom'
import { AlertColor, Avatar } from '@mui/material'
import useToken from '../../services/useToken'
import paths from '../../data/paths.json'
import BackendApi from '../../services/backend'
import data from '../../data/locale/en/data.json'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { GridColDef } from '@mui/x-data-grid'
import FintecyDataGrid from '../../components/FintecyDataGrid'
import { Property } from '../../model/modelTypes'

function PropertiesPage() {
  const { token } = useToken()
  const api = new BackendApi()

  const [rows, setRows] = useState<Property[]>([])
  const [msg, setMsg] = useState(
    data.msg.recordDeleted.replace('$type', data.transactions.title) as string,
  )
  const [alertType, setAlertType] = useState('success' as AlertColor)
  const [open, setOpen] = useState(false)

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason !== 'clickaway') setOpen(false)
  }

  useEffect(() => {
    api
      .getProperties(token)
      .then((rsp: Property[]) => setRows(rsp))
      .catch((error: any) => {
        console.log(error)
      })
  }, [api, token])

  async function deleteProperty(id: string) {
    //FIXME
    // api.deleteOrganization(id, token)
    //     .then(() => {
    //         setRows({...rows})
    //     })
  }

  // @ts-ignore
  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: data.table.actions,
      description: 'supported operations',
      sortable: false,
      renderCell: (params) => (
        <ButtonGroup variant="text" color="primary" aria-label="wallets controls">
          <Tooltip title={data.btn.update}>
            <IconButton
              component={Link}
              to={paths.editOrganisation.replace(':id', params.row.id || paths.new)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={data.btn.delete}>
            <IconButton onClick={() => deleteProperty(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      ),
    },
    {
      field: 'type',
      headerName: data.properties.field.type,
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'name',
      headerName: data.properties.field.name,
      flex: 2,
      type: 'string',
      editable: false,
      renderCell: (params) =>
        params.row.address.city + "'s " + params.row.type.toLowerCase(),
    },
    {
      field: 'addressLine',
      headerName: data.properties.field.addressLine,
      flex: 2,
      type: 'string',
      editable: false,
      renderCell: (params) => params.row.address.addressLine,
    },
    {
      field: 'city',
      headerName: data.properties.field.city,
      flex: 1,
      type: 'string',
      editable: false,
      renderCell: (params) => params.row.address.city,
    },
    {
      field: 'postCode',
      headerName: data.properties.field.postcode,
      flex: 1,
      type: 'string',
      editable: false,
      renderCell: (params) => params.row.address.postCode,
    },
    {
      field: 'country',
      headerName: data.organisations.field.country,
      description: 'ISO country code',
      type: 'string',
      sortable: true,
      renderCell: (params) => (
        <Avatar
          alt={params.row.address.country}
          src={`/flags/${params.row.address.country?.substring(0, 2).toLowerCase()}.webp`}
        />
      ),
    },
  ]
  return (
    <FintecyDataGrid
      title={data.properties.title}
      addProperty
      addNewLink={paths.addProperty}
      rows={rows}
      columns={columns}
      openSnackbar={open}
      handleSnackbarClose={handleSnackbarClose}
      alertType={alertType}
      msg={msg}
    />
  )
}

export default PropertiesPage
