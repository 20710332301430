import List from '@mui/material/List'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ListItem } from '@mui/material'
import { miniApps } from './MiniApps'
import AppsIcon from '@mui/icons-material/Apps'
import paths from '../data/paths.json'
import data from '../data/locale/en/data.json'

// @ts-ignore
export default function MainMenuItems({ drawerOpen }) {
  return (
    <Box style={{ maxHeight: '100vh', overflow: 'auto' }}>
      <List>
        {miniApps.map(
          (value) =>
            value.enabled &&
            !value.premium && (
              <ListItem component={Link} to={value.path} key={value.path}>
                {drawerOpen ? (
                  <>
                    <ListItemIcon>{value.icon}</ListItemIcon>
                    <ListItemText primary={value.name} />
                  </>
                ) : (
                  value.icon
                )}
              </ListItem>
            ),
        )}
      </List>
      <Divider />
      <List>
        <ListItem component={Link} to={paths.miniApps}>
          {drawerOpen ? (
            <>
              <ListItemIcon>
                <AppsIcon />
              </ListItemIcon>
              <ListItemText primary={data.title.miniApps} />
            </>
          ) : (
            <AppsIcon />
          )}
        </ListItem>
        {miniApps.map(
          (value) =>
            value.enabled &&
            value.premium && (
              <ListItem component={Link} to={value.path} key={value.path}>
                {drawerOpen ? (
                  <>
                    <ListItemIcon>{value.icon}</ListItemIcon>
                    <ListItemText primary={value.name} />
                  </>
                ) : (
                  value.icon
                )}
              </ListItem>
            ),
        )}
      </List>
    </Box>
  )
}

MainMenuItems.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
}
