import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import useExpenses from '../services/useExpenses';

const CumulativeExpensesWidget: React.FC = () => {
    const expenseData = useExpenses();

    if (!expenseData || !expenseData.txs) {
        return <p>Loading expenses...</p>;
    }

    // Extract transactions from expenseData
    const transactions = expenseData.txs;

    // Prepare data for cumulative expenses and remaining salary
    const transactionsByDate = transactions.reduce((acc, tx) => {
        const date = tx.asOf.slice(0, 10); // Extract the date part (YYYY-MM-DD)
        acc[date] = acc[date] || { salary: 0 };

        if (tx.category.toLowerCase() === 'salary' && tx.toAmount.amount > 0) {
            acc[date].salary += tx.toAmount.amount; // Add salary to the date
        } else if (tx.toAmount.amount < 0) {
            const category = tx.category;
            acc[date][category] = (acc[date][category] || 0) + Math.abs(tx.toAmount.amount); // Accumulate expenses
        }

        return acc;
    }, {} as Record<string, Record<string, number>>);

    // Get all unique categories for stacking purposes
    const categories = Array.from(
        new Set(transactions.filter(value => value.toAmount.amount < 0).map((tx) => tx.category))
    );

    const sortedDates = Object.keys(transactionsByDate).sort();
    const cumulativeData: Array<Record<string, number | string>> = [];
    let totalSalary = 0;

    sortedDates.forEach((date, index) => {
        const dayData: Record<string, number | string> = { date };

        // Update totalSalary by adding current day's salary, if any
        totalSalary += transactionsByDate[date].salary || 0;

        // Calculate cumulative expenses for each category
        let totalExpenses = 0;
        categories.forEach((category) => {
            const previousValue = index > 0 ? (cumulativeData[index - 1][category] as number) : 0;
            dayData[category] = previousValue + (transactionsByDate[date][category] || 0);
            totalExpenses += dayData[category] as number;
        });

        // Calculate the remaining salary after expenses
        dayData['Remaining Salary'] = totalSalary - totalExpenses;

        cumulativeData.push(dayData);
    });

    return (
        <div>
            <h2>Cumulative Expenses and Remaining Salary</h2>
            <ResponsiveContainer width="100%" height={400}>
                <AreaChart data={cumulativeData} stackOffset="expand">
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {categories.map((category, index) => (
                        <Area
                            key={category}
                            type="monotone"
                            dataKey={category}
                            stackId="1"
                            stroke={`hsl(${(index * 360) / categories.length}, 70%, 50%)`}
                            fill={`hsl(${(index * 360) / categories.length}, 70%, 50%)`}
                        />
                    ))}
                    <Area
                        type="monotone"
                        dataKey="Remaining Salary"
                        stackId="1"
                        stroke="#000"
                        fill="#999"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default CumulativeExpensesWidget;
