import React, {useState} from 'react'
import Copyright from '../../components/Copyright'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import {Theme} from '@mui/material/styles/createTheme'
import Container from '@mui/material/Container'
import paths from '../../data/paths.json'
import {AuthToken} from "../../api/api";
import Alert from "@mui/material/Alert";
import {CircularProgress, Fade} from "@mui/material";
import Divider from "@mui/material/Divider";
import {AuthService} from "../../services/api/auth.service";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

interface State {
    username: string
    password: string
    showPassword: boolean
}

export default function SignUp() {
    const classes = useStyles()

    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState<State>({
        username: '',
        password: '',
        showPassword: false,
    })
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('Cannot login, username or password is wrong!')

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        setLoading((prevLoading) => !prevLoading)
        await AuthService
            .passKeyRegister(values.username)
            // @ts-ignore
            .then((token: AuthToken) => token ?? setToken(token))
            .catch((reason: any) => {
                setLoading(() => false)
                setErrorMsg(reason)
                setError(true)
            })
    }

    const handleChange =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value })
        }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>{error && <Alert severity="error">{errorMsg.toString()}</Alert>}
                {loading ? (
                    <Fade
                        in={loading}
                        style={{
                            transitionDelay: loading ? '800ms' : '0ms',
                        }}
                        unmountOnExit
                    >
                        <CircularProgress/>
                    </Fade>
                ) : (
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                )}
                <Typography component="h1" variant="h5">
                    Welcome to Fintecy
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit} id="webauthn-form">
                    <input type="hidden" id="webauthn-input" autoComplete="webauthn"/>
                    <Typography align={"center"}>
                        Enter your email to register.
                    </Typography>
                    <Grid container spacing={2} py={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                value={values.username}
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={handleChange('username')}
                            />
                        </Grid>
                        {/*<Grid item xs={12}>*/}
                        {/*    <TextField*/}
                        {/*        variant="outlined"*/}
                        {/*        required*/}
                        {/*        fullWidth*/}
                        {/*        name="password"*/}
                        {/*        label="Password"*/}
                        {/*        type="password"*/}
                        {/*        id="password"*/}
                        {/*        autoComplete="current-password"*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12}>*/}
                        {/*    <FormControlLabel*/}
                        {/*        control={<Checkbox value="allowExtraEmails" color="primary"/>}*/}
                        {/*        label="I want to receive marketing emails."*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                    </Grid>
                    <Grid py={2}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            href={paths.public.confirm}
                        >
                            Sign Up
                        </Button>
                    </Grid>

                    <Grid py={2}>
                        <Divider orientation="horizontal" variant="middle" flexItem/>
                    </Grid>
                    <Typography component="p" variant="inherit" pb={2} align="center">Already have an
                        account?</Typography>
                    <Button
                        type="button"
                        fullWidth
                        variant={'outlined'}
                        href={'/log-in'}
                        color="primary"
                    >
                        Log in
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright/>
            </Box>
        </Container>
    )
}
