import React, { useState } from 'react'
import Copyright from '../../components/Copyright'
import CssBaseline from '@mui/material/CssBaseline'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Alert from '@mui/material/Alert'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles/createTheme'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  Fade,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { Visibility, VisibilityOff, Edit, Email, Fingerprint, Password } from '@mui/icons-material'
import { AuthToken } from '../../api'
import Divider from "@mui/material/Divider";
import {AuthService} from "../../services/api/auth.service";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue?
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

interface State {
  username: string
  password: string
  showPassword: boolean
}

// @ts-ignore
export default function LogIn({ setToken }) {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState<State>({
    username: '',
    password: '',
    showPassword: false,
  })

  const [step, setStep] = useState<'email' | 'confirmation' | 'password'>('email');
  const setEmailStep = async (e: any) => {
      e.preventDefault()
      if (values.username) {
          setStep('email');
      }
  };
  const setConfirmationStep = async (e: any) => {
      e.preventDefault()
      if (values.username) {
          setStep('confirmation');
      }
  };
  const handlePasswordSubmit = async (e: any) => {
      e.preventDefault()
      if (values.username) {
          setStep('password');
      }
  };

  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('Cannot login, username or password is wrong!')

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleSmartLogIn = async (e: any) => {
    e.preventDefault()
    setLoading((prevLoading) => !prevLoading)
    await AuthService.passKeyLogin(values.username)
        // @ts-ignore
        .then((token: AuthToken) => setToken(token))
        .catch((reason: any) => {
          setLoading(() => false)
          setErrorMsg(reason)
          setError(true)
        })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setLoading((prevLoading) => !prevLoading)
    await AuthService.login(values.username, values.password)
      // @ts-ignore
      .then((token: AuthToken) => setToken(token))
      .catch((reason: any) => {
        setLoading(() => false)
        setErrorMsg(reason)
        setError(true)
      })
  }
  return (
      <Container component="main" maxWidth="xs">
          <CssBaseline/>
          <div className={classes.paper}>
              {error && <Alert severity="error">{errorMsg.toString()}</Alert>}
              {loading ? (
                  <Fade
                      in={loading}
                      style={{
                          transitionDelay: loading ? '800ms' : '0ms',
                      }}
                      unmountOnExit
                  >
                      <CircularProgress/>
                  </Fade>
              ) : (
                  <Avatar className={classes.avatar}>
                      {step === 'email' ? <Email/> : step === 'confirmation' ? <Fingerprint/> : step === 'password' ? <Password/> : <LockOpenIcon/>}
                  </Avatar>
              )}
              <Typography component="h1" variant="h5">
                  Welcome to Fintecy
              </Typography>
              <form className={classes.form} onSubmit={handleSubmit} id="webauthn-form">
                  <input type="hidden" id="webauthn-input" autoComplete="webauthn"/>
                  <Typography align={"center"}>
                      {step === 'email' ? 'Enter your email to continue.'
                          : step === 'confirmation' ? 'Click login button and follow your browser\'s steps.'
                              : step === 'password' ? 'Enter your password and click login button.' : ''}
                  </Typography>
                  <Grid py={2}>
                      <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-password">{'Email Address'}</InputLabel>
                          <OutlinedInput
                              id="email"
                              type={'text'}
                              value={values.username}
                              label="Email Addres"
                              disabled={step === 'confirmation' || step === 'password'}
                              required
                              fullWidth
                              name="email"
                              autoComplete="email"
                              autoFocus
                              onChange={handleChange('username')}
                              endAdornment={(step === 'confirmation' || step === 'password') && (
                                  <InputAdornment position="end">
                                      <IconButton
                                          aria-label="toggle email edit"
                                          onClick={setEmailStep}
                                          edge="end"
                                      >
                                          <Edit/>
                                      </IconButton>
                                  </InputAdornment>)
                              }
                          />
                      </FormControl>
                  </Grid>
                  {(step === 'password' || step === 'confirmation') && (
                      <FormControl fullWidth variant="outlined">
                          <InputLabel
                              htmlFor="outlined-adornment-password">{step === 'password' ? 'Password' : 'Awaiting device confirmation...'}</InputLabel>
                          <OutlinedInput
                              id="outlined-adornment-password"
                              type={values.showPassword ? 'text' : 'password'}
                              value={step === 'password' ? values.password : ''}
                              label={step === 'password' ? 'Password' : 'Awaiting device confirmation...'}
                              disabled={step === 'confirmation'}
                              onChange={step === 'password' ? handleChange('password') : () => {
                              }}
                              endAdornment={step === 'password' && (
                                  <InputAdornment position="end">
                                      <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                      >
                                          {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                      </IconButton>
                                  </InputAdornment>)
                              }
                          />
                      </FormControl>
                  )}
                  {step === 'password' &&
                      <Link href={'/forgot-password'} variant="body2">
                          Forgot password?
                      </Link>}
                  <Grid py={2}>
                      <Button
                          type={step === "email" ? "button" : "submit"}
                          fullWidth
                          variant={loading ? 'outlined' : 'contained'}
                          color="primary"
                          className={classes.submit}
                          onClick={step === 'email' ? setConfirmationStep : step === "confirmation" ? handleSmartLogIn : handleSubmit}
                      >
                          {loading ? 'Loading...' : step === "email" ? 'Continue' : 'Log In'}
                      </Button>
                  </Grid>

                  {(step === 'password' || step === 'confirmation') && (
                      <Button
                          type={"button"}
                          fullWidth
                          variant={'text'}
                          color="primary"
                          onClick={step === 'confirmation' ? handlePasswordSubmit : setConfirmationStep}
                      >
                          {loading ? 'Loading...' : step === 'confirmation' ? 'Use password' : 'Use Fingerprint or Face Recognition'}
                      </Button>
                  )}

                  <Grid py={2}>
                      <Divider orientation="horizontal" variant="middle" flexItem/>
                  </Grid>
                  <Typography component="p" variant="inherit" pb={2} align="center">Don't have an account?</Typography>
                  <Button
                      type="button"
                      fullWidth
                      variant={'outlined'}
                      href={'/sign-up'}
                      color="primary"
                  >
                      Sign Up
                  </Button>
              </form>
          </div>
          <Box mt={8}>
              <Copyright/>
          </Box>
      </Container>
  )
}
LogIn.propTypes = {
  setToken: PropTypes.func.isRequired,
}
