import { useQuery } from "@tanstack/react-query";
import { TransactionService } from "../api/transaction.service"
import { getToken } from "../api/api";
import { QueryKeys } from "./queryKeys";
import features from '../../data/features.json'

export const useGetTransactions = ({ accountId, enabled = true }: { accountId: string, enabled?: boolean }) => {
  const token = getToken();
  return useQuery({
    enabled: features.queries.transactions && enabled && !!token,
    queryFn: () => TransactionService.getTransactions(accountId),
    queryKey: [QueryKeys.Transactions, accountId],
  });
};
