import { api } from "./api";
import {Transaction} from "../../model/transaction.type";

export enum TransactionEndpoint {
    Transactions = "/transactions",
}

export const TransactionService = {
    getTransactions: async (accountId: string, from: string | null = null, to: string | null = null) => {
        try {
            return await api.get<Transaction[]>(
                `${TransactionEndpoint.Transactions}` + (accountId ? `?account=${accountId}` : ""),
            ).then((response) => response?.data ?? [])
        } catch (error) {
            return [
                { asOf: "2024-10-25", toAmount: {amount: 4300, currency: "GBP"}, ref: "Bank Transfer", category: "Salary" },
                { asOf: "2024-10-27", toAmount: {amount: -2000, currency: "GBP"}, ref: "Bank Card", category: "Mortgage" },
                { asOf: "2024-10-28", toAmount: {amount: -200, currency: "GBP"}, ref: "Bank Card", category: "Electricity" },
                { asOf: "2024-11-01", toAmount: {amount: -150, currency: "GBP"}, ref: "Bank Card", category: "Gas" },
                { asOf: "2024-11-05", toAmount: {amount: -100, currency: "GBP"}, ref: "Bank Card", category: "Water" },
                { asOf: "2024-11-10", toAmount: {amount: -50, currency: "GBP"}, ref: "Bank Card", category: "Internet" },
                { asOf: "2024-11-15", toAmount: {amount: -100, currency: "GBP"}, ref: "Bank Card", category: "Mobile Phone" },
                { asOf: "2024-11-20", toAmount: {amount: -100, currency: "GBP"}, ref: "Bank Card", category: "Council Tax" },
                { asOf: "2024-10-26", toAmount: {amount: -50, currency: "GBP"}, ref: "Bank Card", category: "Groceries" },
                { asOf: "2024-10-29", toAmount: {amount: -100, currency: "GBP"}, ref: "Bank Card", category: "Dining Out" },
                { asOf: "2024-10-31", toAmount: {amount: -40, currency: "GBP"}, ref: "Bank Card", category: "Transportation" },
                { asOf: "2024-11-03", toAmount: {amount: -20, currency: "GBP"}, ref: "Bank Card", category: "Coffee" },
                { asOf: "2024-11-07", toAmount: {amount: -150, currency: "GBP"}, ref: "Bank Card", category: "Groceries" },
                { asOf: "2024-11-09", toAmount: {amount: -30, currency: "GBP"}, ref: "Bank Card", category: "Entertainment" },
                { asOf: "2024-11-12", toAmount: {amount: -60, currency: "GBP"}, ref: "Bank Card", category: "Fuel" },
                { asOf: "2024-11-14", toAmount: {amount: -200, currency: "GBP"}, ref: "Bank Card", category: "Shopping" },
                { asOf: "2024-11-17", toAmount: {amount: -50, currency: "GBP"}, ref: "Bank Card", category: "Groceries" },
                { asOf: "2024-11-19", toAmount: {amount: -100, currency: "GBP"}, ref: "Bank Card", category: "Dining Out" },
                { asOf: "2024-11-22", toAmount: {amount: -300, currency: "GBP"}, ref: "Bank Card", category: "Miscellaneous" },
                { asOf: "2024-11-24", toAmount: {amount: -200, currency: "GBP"}, ref: "Bank Card", category: "Home Maintenance" }
            ] as Transaction[];
        }
    },
};
