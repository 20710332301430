import React, { useState } from 'react'
import { AlertColor } from '@mui/material'
import { Link } from 'react-router-dom'
import ButtonGroup from '@mui/material/ButtonGroup'
import paths from '../../../data/paths.json'

import { TransactionsApi } from '../../../api/api'
import useToken from '../../../services/useToken'
import data from '../../../data/locale/en/data.json'
import { GridColDef } from '@mui/x-data-grid'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import FintecyDataGrid from '../../../components/FintecyDataGrid'
import {useGetTransactions} from "../../../services/hooks/useGetTransactions";
import {ACCOUNT_ID} from "../../../data/consts";

function TransactionsPage() {
  const { token } = useToken()
  const api = new TransactionsApi()

  const {
    data: rows,
    isError: isErrorLoadingOrders,
    isLoading: isLoadingOrders,
  } = useGetTransactions({
    accountId: ACCOUNT_ID,
  });
  const [msg, setMsg] = useState(
    data.msg.recordDeleted.replace('$type', data.transactions.title) as string,
  )
  const [alertType, setAlertType] = useState('success' as AlertColor)
  const [open, setOpen] = useState(false)

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason !== 'clickaway') setOpen(false)
  }

  async function deleteTx(id: string) {
    const options = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
    api
      .deleteTransaction(id, options)
      .then(() => {
        setAlertType('success')
        setMsg(data.msg.recordDeleted.replace('$type', data.transactions.title))
        setOpen(true)
      })
      .catch((reason) => {
        setMsg(reason)
        setAlertType('warning')
        setOpen(true)
      })
  }

  // @ts-ignore
  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: data.table.actions,
      description: 'supported operations',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <ButtonGroup variant="text" color="primary" aria-label="wallets controls">
          <Tooltip title={data.btn.update}>
            <IconButton
              component={Link}
              to={paths.editTransaction.replace(':id', params.row.id || paths.new)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={data.btn.delete}>
            <IconButton onClick={() => deleteTx(params.row.id || '')}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={data.btn.copyId}>
            <IconButton onClick={() => navigator.clipboard.writeText(params.row.id ?? "")}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      ),
    },
    {
      field: 'asOfDate',
      headerName: data.transactions.field.asOfDate,
      flex: 1,
      editable: false,
      renderCell: (params) => params.row.asOf.substring(0, 10),
    },
    {
      field: 'asOfTime',
      headerName: data.transactions.field.asOfTime,
      flex: 1,
      editable: false,
      renderCell: (params) => params.row.asOf.substring(11, 19),
    },
    {
      field: 'fromAccount',
      headerName: data.transactions.field.fromAccount.accountType,
      flex: 1,
      type: 'number',
      editable: false,
      renderCell: (params) => params.row.fromAccount.accountType,
    },
    {
      field: 'amount',
      headerName: data.transactions.field.toAmount.amount,
      flex: 1,
      type: 'number',
      editable: false,
      renderCell: (params) => params.row.toAmount.amount,
    },
    {
      field: 'currency',
      headerName: data.transactions.field.toAmount.currency,
      flex: 1,
      type: 'string',
      editable: false,
      renderCell: (params) => params.row.toAmount.currency,
    },
    {
      field: 'category',
      headerName: data.transactions.field.category,
      flex: 1,
      type: 'string',
      editable: false,
    },
    {
      field: 'ref',
      headerName: data.transactions.field.ref,
      flex: 2,
      type: 'string',
      editable: false,
    },
    {
      field: 'status',
      headerName: data.transactions.field.status,
      flex: 1,
      type: 'string',
      editable: false,
    },
  ]
  return (
    <FintecyDataGrid
      title={data.transactions.title}
      addNewLink={paths.addTransaction}
      rows={rows}
      columns={columns}
      openSnackbar={open}
      handleSnackbarClose={handleSnackbarClose}
      alertType={alertType}
      msg={msg}
    />
  )
}

export default TransactionsPage
