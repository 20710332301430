import axios from 'axios'
import paths from '../../data/paths.json'
import { jwtDecode } from 'jwt-decode';

export const BASE_PATH = 'https://api.fintecy.org'.replace(/\/+$/, '')

export function getToken() {
  try {
    let tokenString = localStorage.getItem('token');
    const tokenData = tokenString ? JSON.parse(tokenString) : undefined;
    return tokenData?.accessToken;
  } catch (error) {
      console.error('Access token fetch failed:', error);
      localStorage.removeItem('token');
      return Promise.reject(error);
  }
}

export const api = axios.create({
  baseURL: BASE_PATH,
  timeout: 3000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // 'Authorization': "Bearer " + token
  },
})
api.interceptors.request.use(config => {
  if ('/auth/login' !== config.url && config.baseURL === BASE_PATH) {
    const token = getToken()
    if (token) {
        const decoded = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        if (decoded.exp && decoded.exp > currentTime) {
            config.headers.Authorization = `Bearer ${token}`;
        } else {
            console.error('Token expired at ', decoded);
            localStorage.removeItem('token');
            if (window.location.pathname !== paths.public.logIn) {
                window.location.href = paths.public.logIn;
            }
        }
    }
  } else {
    console.log('Login request detected: ', config.baseURL + ' ' + config.url);
  }
  return config;
}, error => {
  return Promise.reject(error);
});
api.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
       console.log('Error response received:', JSON.stringify(error));
      if ('/auth/login' !== error.config.url) {
        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
            console.error('401/403 error received:', error);
            localStorage.removeItem('token');
            window.location.href = paths.public.logIn;
            return Promise.reject(error);
        }
      } else {
        console.log('Login response detected: ', error.config.baseURL + ' ' + error.config.url);
      }
    }
);
