import React from 'react';
import {ACCOUNT_ID} from "../data/consts";
import {useGetTransactions} from "../services/hooks/useGetTransactions";
import {Transaction} from "../model/transaction.type";

const TransactionsTable: React.FC = () => {

    const {
        data: transactions,
        isError: isErrorLoadingOrders,
        isLoading: isLoadingOrders,
    } = useGetTransactions({
        accountId: ACCOUNT_ID,
    });
    return (
        <div className="overflow-x-auto">
            <table className="min-w-full border-collapse border border-gray-200">
                <thead className="bg-gray-100">
                <tr>
                    <th className="border border-gray-300 px-4 py-2 text-left">Date</th>
                    <th className="border border-gray-300 px-4 py-2 text-left">Amount</th>
                    <th className="border border-gray-300 px-4 py-2 text-left">Currency</th>
                    <th className="border border-gray-300 px-4 py-2 text-left">Source</th>
                    <th className="border border-gray-300 px-4 py-2 text-left">Category</th>
                </tr>
                </thead>
                <tbody>
                {(transactions ?? []).map((transaction: Transaction, index: number) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        <td className="border border-gray-300 px-4 py-2">{transaction.asOf.substring(0, 8)}</td>
                        <td className={`border border-gray-300 px-4 py-2 ${transaction.toAmount.amount < 0 ? 'text-red-600' : 'text-green-600'}`}>
                            £{Math.abs(transaction.toAmount.amount).toFixed(2)}
                        </td>
                        <td className="border border-gray-300 px-4 py-2">{transaction.toAmount.currency}</td>
                        <td className="border border-gray-300 px-4 py-2">{transaction.ref}</td>
                        <td className="border border-gray-300 px-4 py-2">{transaction.category}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default TransactionsTable;
